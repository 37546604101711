$(function() {
    // Referenssisivun lightbox
    $('.js-reference-images').photoSwipe('a', {
        bgOpacity: 0.9,
        history: false,
        shareEl: false,
        fullscreenEl: false,
        zoomEl: false
    });

    // Masonry Referenssit-sivulle
    var $references_list = $('.js-references-masonry');
    $references_list.imagesLoaded(function() {
        $references_list.masonry({
            itemSelector: '.js-references-masonry-item',
            columnWidth: '.js-references-masonry-item'
        });
    });
});