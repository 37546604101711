;(function($) {

    function CheckAllButton(element, options) {
        this.options = options || {};
        this.$element = $(element);
        if (!this.options.selector) {
            this.options.selector = this.$element.data("checkAll");
        }
        this.$target = $(this.options.selector);
        this.init();
    }

    CheckAllButton.prototype.init = function() {
        this.$element.on("change.checkAllButton", this.toggleCheckAll.bind(this));
        this.$target.on("change.checkAllButton", this.individualCheckedChange.bind(this));
    };

    CheckAllButton.prototype.toggleCheckAll = function() {
        this.$element.prop("indeterminate", false);
        this.$target.prop("checked", this.$element.prop("checked"));
    };

    CheckAllButton.prototype.individualCheckedChange = function() {
        var some = false;
        var all = true;
        for (var i = 0; i < this.$target.length; i++) {
            if (this.$target[i].checked) {
                some = true;
            } else if (!this.$target[i].checked) {
                all = false;
            }
        }
        if (all) {
            this.$element.prop("checked", true).prop("indeterminate", false);
        } else if (some) {
            this.$element.prop("checked", false).prop("indeterminate", true);
        } else {
            this.$element.prop("checked", false).prop("indeterminate", false);
        }
    };


    $.fn.checkAllButton = function(options) {
        return this.each(function() {
            if ($(this).data("checkAllButtonObject") === undefined) {
                $(this).data("checkAllButtonObject", new CheckAllButton(this, options || {}));
            }
        });
    };

})(jQuery);