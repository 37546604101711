$(function() {

    // Klikattaessa toimitusosoitteita, täytetään toimitusosoite-formi
    $(".previous_shipping_addresses a").on('click', function(e) {
        $(this).find(".fill").each(function(i, obj) {
            var target = $(obj).data("target");
            var value = $(obj).text();
            $(target).val(value);
        });

        e.preventDefault();
    });

    // Lisätään toimitusosoitteen tyhjennysnappi
    $(".previous_shipping_addresses .list-group").append('<a href="#" class="list-group-item" id="clear_shipping_address">Tyhjennä toimitusosoite</a>');


    // Klikattaessa toimitusosoitteita, täytetään toimitusosoite-formi
    $("#clear_shipping_address").click(function(e) {
        clear_form_elements("#checkout_shipping_details");
        e.preventDefault();
    });

    // Ostoskorin nappuloiden piilotus ja näyttäminen
    $('#update_cart').hide();

    // Kertaluontoisesti swapataan buttoni, kun jokin input muuttuu
    $('#checkout_products').one('input.updatecart change.updatecart', 'input', function() {
        $('#cart_confirm').hide();
        $('#update_cart').show();
    });

    function patch_order_info_all() {
        data = JSON.stringify($("#form_order").serializeJSON());
        $.ajax({
            url: $("#form_order").data("patch-url"),
            data: data,
            contentType : 'application/json',
            type: "PATCH",
            success: function(response) {
                // Lähetetään tilauksen päivityksen onnistumisesta eventti
                // Otetaan kiinni esim. noutopistehaussa
                EventBus.dispatch("order.update", null, response);
            }
        });
    }

    // Kun lomakkeessa muuttuu jokin kriittinen tieto, patchataan tilaus palvelimelle
    $('#select_shipping_method, #country, #delivery_country, #postal_code, #delivery_postal_code, #address, #delivery_address').on('change', patch_order_info_all);

    // Kun toimitustapa-selectin arvo muuttuu, vaihdetaan details-kentän teksti
    $('#select_shipping_method').on('change', function() {
        var $selected = $(this).find('option:selected');
        $('#shipping-details').text($selected.data('details'));
        $('#shipping-price').text($selected.data('price'));
    });

    // Enterin painallus kampanjakoodikentässä = Päivitä ostoskori
    $('input#order_campaigns_code').bind("enterKey", function(e) {
        $('button#update_cart').click();
    });

    $('input#order_campaigns_code').keydown(function(e) {
        if (e.keyCode == 13) {
            e.preventDefault();
            $(this).trigger("enterKey");
        }
    });

});
