$(function() {
    /**
     * Required validointi
     */
    ;(function() {
        function DisableEvent(e) {
            if (e.returnValue) e.returnValue = false;
            if (e.cancelBubble) e.cancelBubble = true;
            if (e.preventDefault) e.preventDefault();
            if (e.stopPropagation) e.stopPropagation();
            if (e.stopImmediatePropagation) e.stopImmediatePropagation();
            return false;
        }

        $("select[required], input[required], textarea[required]").addClass("required").removeAttr("required");
        $.each($("[pattern]"), function (index, item) {
            $(item).attr('data-pattern', $(item).attr("pattern")).removeAttr("pattern");
        });

        function getUnansweredTags(form) {
            /// <summary>Get list of required tags that haven't been answered.</summary>
            var tags = [];
            $("select.required, input.required, textarea.required", form).filter(":visible").each(function(i, c) {
                var $c = $(c);
                // Checkbox / radio:
                if ($c.is(':checkbox') || $c.is(':radio')) {
                    var c_name = $c.attr('name');
                    // Tarkistetaan sekä input että sen name-kaverit checked-propertyn varalta
                    var $c_group = $(':checkbox, :radio').filter('[name="' + c_name + '"]');
                    // Ei yhtään checkattua checkboxia / radiobuttonia:
                    if (!$c_group.filter(':checked').length) {
                        tags.push($c);
                    }
                }
                // Muut inputit:
                else if (!$c.val()) {
                    tags.push($c);
                }
            });
            return tags;
        }

        function getUnmatchedTags(form) {
            /// <summary>Get tags that fail the pattern match.</summary>
            var tags = [];
            $("input[data-pattern][title], textarea[data-pattern][title]", form).filter(":visible").each(function(i, c) {
                var $c = $(c);
                var expEngine = new RegExp($c.attr("data-pattern"));
                if (!expEngine.test($c.val()) && $c.val() !== '') {
                    tags.push($c);
                }
            });
            return tags;
        }

        function getUnmatchedTitles(form) {
            /// <summary>Get the titles associated with the tags that fail the pattern match.</summary>
            var titles = [];
            $("input[data-pattern][title], textarea[data-pattern][title]", form).filter(":visible").each(function(i, c) {
                titles.push($(c).attr("title"));
            });
            return titles;
        }

        var parentsSelector = '.form-group:first, .checkbox:first';

        $("form:not([novalidate])").on("submit", function (e) {
            $(".has-error", this).removeClass("has-error");
            var unanswered = getUnansweredTags(this);
            if (unanswered.length > 0) {
                $('html, body').animate({ scrollTop: unanswered[0].parents(parentsSelector).offset().top }, function() {
                    unanswered[0].focus();
                });
                $(unanswered).each(function(i, item) {
                    item.parents(parentsSelector).addClass("has-error");
                });
                return DisableEvent(e);
            }
        });

        $("form:not([novalidate])").on("submit", function (e) {
            $(".has-error", this).removeClass("has-error");
            var unmatched = getUnmatchedTags(this);
            if (unmatched.length > 0) {
                $('html, body').animate({ scrollTop: unmatched[0].parents(parentsSelector).offset().top }, function() {
                    unmatched[0].focus();
                });
                $(unmatched).each(function(i, item) {
                    item.parents(parentsSelector).addClass("has-error");
                });
                alert(getUnmatchedTitles().join("\r\n\r\n"));
                return DisableEvent(e);
            }
        });

    })();
});
