(function($) {

    var supports = {
        objectUrl: typeof URL !== "undefined" && URL.createObjectURL !== undefined,
    };

    var defaultOptions = {
        placeholder: "../img/image-input-upload.png",
        uploadPlaceholder: "../img/image-input-ok.png",
        allowDelete: true,
        allowRevert: true,
        allowOpenOriginal: true,
        deleteInputName: null,
        wrapperClass: "image-upload__item",
        uploadClass: "image-upload__item--has-upload",
        placeholderClass: "image-upload__item--placeholder",
        classes: {
            button: "image-upload__cancel btn btn-xs btn-warning",
        },
    };

    function ImageUpload(element, options) {
        this.element = $(element);
        if (options.placeholder === undefined) {
            options.placeholder = this.element.data("placeholder");
        }
        if (options.uploadPlaceholder === undefined) {
            options.uploadPlaceholder = this.element.data("uploadPlaceholder");
        }
        if (this.element.data("options") !== undefined) {
            options = $.extend(true, {}, options, this.element.data("options"));
        }
        this.options = $.extend(true, {}, defaultOptions, options);
        this.initialize();
    }

    /**
     * Initialize the plugin.
     */
    ImageUpload.prototype.initialize = function() {
        var self = this;

        this.inputs = this.element.find("input[type='file']");
        this.inputs.each(function() {
            if (!self.options.deleteInputName) {
                self.options.deleteInputName = 'delete_' + this.name;
            }
            var input = $(this);
            self.createImageInput(input);
        });
    };

    /**
     * Creates a new image label for the input and appends it to the container.
     * 
     * @param  jQuery input
     */
    ImageUpload.prototype.createImageInput = function(input) {
        // Hide file input
        input.hide();
        // Get src for image
        var src = input.data("src");
        var wrapperClass = this.options.wrapperClass;
        if (src === undefined || src.length === 0 && this.options.placeholder) {
            // Assume this is the placeholder input; there should only be one
            src = this.options.placeholder;
            input.data("src", src);
            wrapperClass += " " + this.options.placeholderClass;
        }
        // Create the wrapper that holds everything related to this item
        var wrapper = this.createElement("div", { class: wrapperClass });
        input.data("imageUploadWrapper", wrapper);
        // Create a label for the input
        var label = this.createElement("label");
        // Append the input inside the label to get the label to work
        input.appendTo(label);
        // Create an image inside the label
        var img = this.createElement("img");
        var isIE = '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style;
        if (isIE) {
            img.css("pointer-events", "none");
        }
        if (src) {
            img.attr("src", src).attr("title", "Lisää kuva").attr("alt", "Lisää kuva");
        } else {
            wrapper.hide();
        }
        img.appendTo(label);
        if (input.data('nextImage') !== undefined) {
            var addNextImage = $('<span class="btn btn-xs btn-default image-upload__add-image"><i class="fa fa-plus-circle"></i>&nbsp;&nbsp;Lisää kuva</span>');
            img.after(addNextImage);
        }
        if (this.options.allowOpenOriginal && input.data('src')) {
            var openOriginalLink = this.createElement("a", { class: "image-upload__original-link" });
            openOriginalLink.attr('target', '_blank').attr('href', input.data('src')).attr('title', 'Avaa uuteen ikkunaan').html('<i class="fa fa-external-link"></i>');
            openOriginalLink.appendTo(label);
        }
        // Append the label to the wrapper
        label.appendTo(wrapper);
        // Create a help text block
        var helpText = this.createElement("span", { class: "help-block small image-upload__help-text", style: "display: none;" });
        helpText.appendTo(wrapper);
        if (this.options.allowDelete && input.data("value")) {
            // Create a delete checkbox
            var deleteCheckbox = this.createElement("input", {
                type: "checkbox",
                name: this.options.deleteInputName,
                value: input.data("value"),
            });
            deleteCheckbox.on("change", this.deleteChange.bind(this, input));
            var deleteLabel = $('<label class="image-upload__delete"> Poista</label>');
            deleteLabel.prepend(deleteCheckbox);
            deleteLabel.appendTo(wrapper);
        }
        if (this.options.allowRevert) {
            // Create a revert button
            var revertButton = this.createElement("button", {
                "data-revert": "revert",
                type: "button",
                style: "display: none;",
            });
            revertButton.html("Peruuta lataus");
            revertButton.on("click", this.revertInput.bind(this, input));
            revertButton.appendTo(wrapper);
        }
        input.wrapper = wrapper;
        // Listen to change events on the input
        input.on("change", this.onInputChange.bind(this, input));
        // Append the label to our element
        this.element.append(wrapper);
        return wrapper;
    };

    /**
     * Reacts to changes in the delete checkbox.
     * 
     * @param  jQuery input
     */
    ImageUpload.prototype.deleteChange = function(input) {
        var checkbox = input.data("imageUploadWrapper").find(".image-upload__delete").find("input");
        var wrapper = input.closest('.image-upload__item');
        var outer_wrapper = input.closest('fieldset');

        if (checkbox.is(":checked")) {
            input.next("img").css("opacity", 0.25);
            wrapper.addClass('image-upload__item--to-delete');
            outer_wrapper.find('.js-help-text-delete').removeClass('hidden');
        } else {
            input.next("img").css("opacity", 1);
            wrapper.removeClass('image-upload__item--to-delete');

            if (outer_wrapper.find('.image-upload__delete :checked').length === 0) {
                outer_wrapper.find('.js-help-text-delete').addClass('hidden');
            }
        }
    };

    ImageUpload.prototype.onInputChange = function(input) {
        var files = input[0].files;
        if (files.length === 0) {
            // Dialog was cancelled, don't do anything
            return;
        }
        this.validateFileList(files)
            .done(this.addFiles.bind(this, input))
            .fail((function(file) {
                this.onValidateError(input, file);
            }).bind(this));
    };

    ImageUpload.prototype.validateFileList = function(files) {
        var deferred = new $.Deferred();
        var resultArray = [];
        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            this.validateFile(file)
                .done(function(file) {
                    resultArray.push(file);
                    if (resultArray.length === files.length) {
                        deferred.resolve(resultArray);
                    }
                })
                .fail(function(file) {
                    deferred.reject(file);
                });
        }
        return deferred.promise();
    };

    /* https://stackoverflow.com/a/32490603 */
    ImageUpload.prototype.getOrientation = function(file, callback) {
        if (typeof FileReader === 'undefined') {
            callback(-1);
            return;
        }
        var reader = new FileReader();
        reader.onload = function(e) {
            var view = new DataView(e.target.result);
            if (view.getUint16(0, false) != 0xFFD8) return callback(-2);
            var length = view.byteLength, offset = 2;
            while (offset < length) {
                var marker = view.getUint16(offset, false);
                offset += 2;
                if (marker == 0xFFE1) {
                    if (view.getUint32(offset += 2, false) != 0x45786966) return callback(-1);
                    var little = view.getUint16(offset += 6, false) == 0x4949;
                    offset += view.getUint32(offset + 4, little);
                    var tags = view.getUint16(offset, little);
                    offset += 2;
                    for (var i = 0; i < tags; i++)
                        if (view.getUint16(offset + (i * 12), little) == 0x0112)
                            return callback(view.getUint16(offset + (i * 12) + 8, little));
                }
                else if ((marker & 0xFF00) != 0xFF00) break;
                else offset += view.getUint16(offset, false);
            }
            return callback(-1);
        };
        reader.readAsArrayBuffer(file);
    }

    /* https://stackoverflow.com/a/40867559 */
    ImageUpload.prototype.autoOrient = function(img, file, callback) {
        this.getOrientation(file, function(srcOrientation) {
            var width = img.width,
                height = img.height,
                canvas = document.createElement('canvas');
            if (!(canvas.getContext && canvas.getContext('2d'))) {
                callback();
                return;
            }
            var ctx = canvas.getContext("2d");
            // set proper canvas dimensions before transform & export
            if (4 < srcOrientation && srcOrientation < 9) {
                canvas.width = height;
                canvas.height = width;
            } else {
                canvas.width = width;
                canvas.height = height;
            }
            // transform context before drawing image
            switch (srcOrientation) {
                case -2: callback(); return; // not a jpg
                case -1: callback(); return; // not defined
                case 1: callback(); return; // top left already, do nothing
                case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
                case 3: ctx.transform(-1, 0, 0, -1, width, height ); break;
                case 4: ctx.transform(1, 0, 0, -1, 0, height ); break;
                case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
                case 6: ctx.transform(0, 1, -1, 0, height , 0); break;
                case 7: ctx.transform(0, -1, -1, 0, height , width); break;
                case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
                default: break;
            }
            // draw image
            ctx.drawImage(img, 0, 0);

            callback(canvas.toDataURL());
        });
    };

    ImageUpload.prototype.validateFile = function(file) {
        // Validate reported mime type
        var deferred = new $.Deferred();
        if (!file.type || file.type.substring(0, 5) !== "image") {
            deferred.reject(file);
        }
        // Validate using an image object
        var src = this.options.uploadPlaceholder;
        if (supports.objectUrl) {
            // Attempt to show the actual image as a placeholder
            src = URL.createObjectURL(file);
        }
        var img = new Image();
        img.onload = (function() {
            img.onload = undefined;
            this.autoOrient(img, file, function(newSrc) {
                file.src = newSrc ? newSrc : src;
                deferred.resolve(file);
            });
        }).bind(this);
        img.onerror = function() {
            deferred.reject(file);
            console.log("fail: " + src);
        };
        img.src = src;
        return deferred.promise();
    };

    /**
     * Updates the image placeholder for the file input.
     * 
     * @param  jQuery input
     */
    ImageUpload.prototype.addFiles = function(input, files) {
        input.wrapper.show();
        var i = 0;
        var file = files[i];
        do {
            input = this.addFile(input, file);
            file = files[++i];
        } while (file && input);
    };

    ImageUpload.prototype.addFile = function(input, file) {
        var wrapper = input.data("imageUploadWrapper");
        input.next("img").css("opacity", 1).attr("src", file.src);
        wrapper.closest('fieldset').find('.js-help-text-upload').removeClass('hidden');
        wrapper.find(".image-upload__delete")
            .hide()
            .find("input[type='checkbox']")
                .prop("checked", true);
        if (input.val().length > 0) {
            wrapper.find("button[data-revert]").show();
        }
        wrapper.addClass(this.options.uploadClass).removeClass(this.options.placeholderClass);
        return this.handleNextImage(input);
    };

    ImageUpload.prototype.onValidateError = function(input, file) {
        this.revertInput(input);
        alert("Error loading " + file.name + " for display, probably not an image");
    };

    /**
     * Reverts an input back to its original state.
     * 
     * @param  jQuery input
     */
    ImageUpload.prototype.revertInput = function(input) {
        var wrapper = input.data("imageUploadWrapper");
        input.trigger("imageupload:revert");
        if (input.data("nextImage") !== undefined && input.data("value") === "new") {
            // This is an image that doesn't yet exist on the server; just remove it
            wrapper.remove();
        } else {
            // Either a next image placeholder or an image that exists on the server, reset
            var src = input.data("src");
            input.val("");
            input.next("img").off("load error").attr("src", src);
            wrapper.removeClass(this.options.uploadClass);
            wrapper.find(".image-upload__delete")
                .show()
                .find("input[type='checkbox']")
                    .prop("checked", false);
            wrapper.find("button[data-revert]").hide();
        }
    };

    /**
     * Creates a next image placeholder out of this input, if applicable.
     * 
     * @param  jQuery input
     */
    ImageUpload.prototype.handleNextImage = function(input) {
        var nextInput;
        if (input.data("nextImage") !== undefined) {
            nextInput = input.clone();
            nextInput.val(null);
            input.data("value", "new");
            this.createImageInput(nextInput);
        } else {
            nextInput = $("[data-next-image]:last", this.element);
        }
        if (nextInput.length > 0) {
            input.one("imageupload:revert", this.revertInput.bind(this, nextInput));
        }
        return nextInput;
    };

    /**
     * Utility function to create a DOM element.
     * Returns a jQuery object.
     * 
     * @param  string type
     * @return jQuery
     */
    ImageUpload.prototype.createElement = function(type, attrs) {
        var elem = $("<" + type + ">");
        if (typeof attrs === "object") {
            elem.attr(attrs);
        }
        if (this.options.classes && this.options.classes[type]) {
            elem.addClass(this.options.classes[type]);
        }
        return elem;
    };

    $.fn.imageUpload = function(options) {
        if ($(this).data("imageUpload") === undefined) {
            this.each(function() {
                var instance = new ImageUpload(this, options || {});
                $(this).data("imageUpload", instance);
            });
            if (this.length > 1) {
                return this;
            }
        }
        return $(this).data("imageUpload");
    };
})(window.jQuery);
