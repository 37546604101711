;(function($) {

    var defaultOptions = {
        inverted: false,
        value: undefined,
        checked: undefined,
        clearInputs: false,
        toggleVisibility: true,
        animate: true,
        targetElement: undefined,
        moveAfter: undefined,
    };

    var Depends = function(element, options) {
        this.element = $(element);
        options = options || {};
        this.options = $.extend({}, defaultOptions, options, this.getDataOptions());
        if (typeof this.options.targetElement === 'undefined') {
            this.options.targetElement = element;
        }
        this.options.targetElement = $(this.options.targetElement);
        this.init();
    };

    Depends.prototype.getDataOptions = function() {
        var options = {};
        var data = this.element.data();
        for (var key in data) {
            if (data.hasOwnProperty(key) && key.length > 7 && key.substring(0, 7) === "depends") {
                var optionKey = key.substring(7).charAt(0).toLowerCase() + key.substring(8);
                if (optionKey in defaultOptions) {
                    options[optionKey] = data[key];
                }
            }
        }
        return options;
    };

    Depends.prototype.init = function() {
        if (this.options.moveAfter) {
            this.options.targetElement.insertAfter($(this.options.moveAfter));
        }
        this.options.targetElement.attr("data-has-depends", "");
        this.dependsOn = $(this.element.data("depends"));
        this.dependsOn.on("change input", this.onChange.bind(this));
        this.onChange();
    };

    Depends.prototype.onChange = function() {
        this.toggleElement(!this.isSatisfied());
    };

    Depends.prototype.isSatisfied = function() {
        var self = this;
        var satisfied = true;
        this.dependsOn.each(function() {
            satisfied = satisfied && self.doesControlSatisfy(this);
        });
        if (satisfied) {
            var parent = self.options.targetElement.parents("[data-has-depends]:first").data("dependsObject");
            if (typeof parent !== 'undefined') {
                satisfied = parent.isSatisfied();
            }
        }
        return satisfied;
    };

    Depends.prototype.doesControlSatisfy = function(control) {
        var satisfied = true;
        if ($(control).is("input[type='radio']") && this.options.checked) {
            if (control.id == this.options.checked) {
                satisfied = control.checked;
            }
        } else if ($(control).prop("disabled")) {
            satisfied = false;
        } else if ($(control).is("input[type='checkbox']")) {
            satisfied = control.checked;
        } else {
            if (typeof this.options.value !== 'undefined') {
                var val = this.getControlValue(control);
                satisfied = val == this.options.value;
            } else {
                satisfied = !!this.getControlValue(control);
            }
        }
        if (this.options.inverted) {
            return !satisfied;
        } else {
            return satisfied;
        }
    };

    Depends.prototype.getControlValue = function(control) {
        if (this.options.value && this.options.value.substring(0, 5) === "data:") {
            var attribute = this.options.value.substring(5);
            var value = "";
            if (attribute.indexOf("=") >= 0) {
                var split = attribute.split("=");
                attribute = split[0].trim();
                value = split[1].trim();
            }
            control = $(control);
            if (control.is("select")) {
                control = control.find("option:selected");
            }
            if (control.data(attribute) == value) {
                return this.options.value;
            }
        } else {
            return $(control).val();
        }
    };

    Depends.prototype.toggleElement = function(disabled) {
        var self = this;
        self.options.targetElement.prop("disabled", disabled);
        $(":input", self.options.targetElement).each(function() {
            // Handle nested depends
            // Only affect those elements that are direct descendants of only this depends element
            var closestDepends = $(this).closest("[data-has-depends]");
            if (closestDepends[0] == self.options.targetElement[0]) {
                // Direct child of this depends, we can safely modify the property
                $(this).prop("disabled", disabled);
            } else {
                // Call onChange on closest depends
                var depends = closestDepends.data("dependsObject");
                if (typeof depends !== 'undefined') {
                    depends.onChange();
                }
            }
        });
        if (self.options.toggleVisibility) {
            if (disabled) {
                self.hideElement();
            } else {
                self.showElement();
            }
        }
    };

    Depends.prototype.hideElement = function() {
        if (this.options.animate) {
            this.options.targetElement.slideUp();
        } else {
            this.options.targetElement.hide();
        }
        this.clearInputs();
    };

    Depends.prototype.showElement = function() {
        if (this.options.animate) {
            this.options.targetElement.slideDown();
        } else {
            this.options.targetElement.show();
        }
    };

    Depends.prototype.clearInputs = function() {
        if (this.options.clearInputs) {
            this.options.targetElement.find(':input').each(function() {
                switch(this.type) {
                    case 'date':
                    case 'datetime':
                    case 'password':
                    case 'text':
                    case 'email':
                    case 'tel':
                    case 'number':
                    case 'textarea':
                    case 'file':
                        if (this.options.value != "") {
                            $(this).val('').trigger("change");
                        }
                        break;
                    case 'checkbox':
                    case 'radio':
                        var hasAttr = typeof $(this).attr("checked") !== 'undefined';
                        if (!hasAttr && this.options.checked) {
                            $(this).prop("checked", false).trigger("change");
                        } else if (hasAttr && !this.options.checked) {
                            $(this).prop("checked", true).trigger("change");
                        }
                        break;
                }
            });
        }
    };

    $.fn.depends = function(options) {
        return this.each(function() {
            if (typeof $(this).data("dependsObject") === 'undefined') {
                var depends = new Depends(this, options);
                depends.options.targetElement.data("dependsObject", depends);
            }
        });
    };

})(jQuery);
