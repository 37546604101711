;(function($) {

    var supports = {
        FormData: typeof FormData !== "undefined",
    };

    var defaultOptions = {
        doneCallback: null,
        // Päivitetäänkö sivu onnistuneella submitilla?
        refreshOnSuccess: false,
        // Poistetaanko lomake sivupohjasta onnistuneella submitilla?
        removeOnSuccess: true,
        // Virheilmoitus, jos AJAX kutsu epäonnistuu
        genericError: "Tuntematon virhe lomakkeen lähetyksessä.",
    };

    function AjaxSubmit(form, options) {
        this.$form = $(form);
        options = options || {};
        if (this.$form.data("options") !== undefined) {
            options = $.extend(true, options, this.$form.data("options"));
        }
        this.options = $.extend({}, defaultOptions, options);
        if (typeof this.options.doneCallback !== "function") {
            this.options.doneCallback = this.onDone;
        }
        this.init();
    }

    AjaxSubmit.prototype.init = function() {
        var $form = this.$form;
        $form.enctype = this.$form.attr("enctype");
        if (!supports.FormData && $form.enctype == "multipart/form-data") {
            return false;
        }
        $form.on("submit", this.onSubmit.bind(this));
        $form.find(":submit").on("click", function() {
            // Jos submit buttonilla on arvo, lisätään se hidden inputtina formin loppuun
            var $this = $(this);
            var name = $this.attr("name");
            // Poistetaan mahdolliset vanhat arvot
            $("#submit_hidden_value", $form).remove();
            if (name !== undefined) {
                $form.append('<input type="hidden" id="submit_hidden_value" name="' + name + '" value="' + $this.val() + '">');
            }
        });
    };

    AjaxSubmit.prototype.getAjaxOpts = function() {
        var opts = {
            url: this.$form.attr("action"),
            method: this.$form.attr("method") || "post",
            dataType: "json",
        };
        if (this.$form.enctype == "multipart/form-data") {
            var data = new FormData();
            this.$form.find(":input:not(button):not([type='submit'])").each(function() {
                if (!this.disabled && this.name && this.name.length) {
                    if (this.files) {
                        // File inputit pitää käsitellä erikseen
                        for (var i = 0; i < this.files.length; i++) {
                            data.append(this.name, this.files[i]);
                        }
                    } else if (this.type == "checkbox" || this.type == "radio") {
                        // Checkboxit ja radio buttonit eivät lähetä mitään, jos ei checkattu
                        if (this.checked) {
                            data.append(this.name, this.value);
                        }
                    } else {
                        // Normaali input
                        data.append(this.name, this.value);
                    }
                }
            });
            opts.data = data;
            opts.contentType = false;
            opts.processData = false;
            if (data.fake) {
                opts.xhr = function() {
                    var xhr = jQuery.ajaxSettings.xhr();
                    xhr.send = xhr.sendAsBinary;
                    return xhr;
                };
                opts.contentType = "multipart/form-data; boundary=" + data.boundary;
                opts.data = data.toString();
            }
        } else {
            opts.data = this.$form.serializeArray();
        }
        return opts;
    };

    AjaxSubmit.prototype.onSubmit = function(e) {
        e.preventDefault();
        var self = this;
        var opts = this.getAjaxOpts(); // Otetaan data ennen formin disabloimista
        this.toggleForm(true).toggleError().toggleSuccess();
        $.ajax(opts)
            .done(this.options.doneCallback.bind(this))
            .fail(function(xhr, status) {
                console.log(xhr);
                self.toggleError(true, self.options.genericError).toggleForm(false);
            });
    };

    AjaxSubmit.prototype.onDone = function(data) {
        if (!data.success) {
            return this.toggleError(true, data.message).toggleForm(false);
        }
        if (data.redirect) {
            return window.location = data.redirect;
        }
        if (data.refreshOnSuccess || this.options.refreshOnSuccess) {
            return window.location.reload();
        }
        this.toggleSuccess(true, data.message).toggleForm(false);
    };

    AjaxSubmit.prototype.toggleForm = function(disabled) {
        this.$form.find(":input").filter(":visible").prop("disabled", disabled);
        return this;
    };

    AjaxSubmit.prototype.toggleError = function(error, message) {
        if (error) {
            $(".ajax-submit-error-container", this.$form).html(this.messageToHtml(message)).fadeIn();
        } else {
            $(".ajax-submit-error-container", this.$form).html("").hide();
        }
        return this;
    };

    AjaxSubmit.prototype.toggleWarning = function(warning, message) {
        if (warning) {
            $(".ajax-submit-warning-container", this.$form).html(this.messageToHtml(message)).fadeIn();
        } else {
            $(".ajax-submit-warning-container", this.$form).html("").hide();
        }
        return this;
    };

    AjaxSubmit.prototype.toggleSuccess = function(success, message) {
        if (success) {
            $(".ajax-submit-success-container", this.$form).html(this.messageToHtml(message)).fadeIn();
            if (this.options.removeOnSuccess) {
                this.$form.replaceWith($(".ajax-submit-success-container", this.$form));
            }
        } else {
            $(".ajax-submit-success-container", this.$form).html("").hide();
        }
        return this;
    };

    AjaxSubmit.prototype.messageToHtml = function(message) {
        var html = "<p>";
        if ($.isArray(message)) {
            html += message.join("</p><p>");
        } else {
            html += message;
        }
        html += "</p>";
        html = html.replace(/(?:\r\n|\r|\n)/g, "<br>");
        return html;
    };

    $.fn.ajaxSubmit = function(options) {
        return this.each(function() {
            if ($(this).data("ajaxSubmitObject") === undefined) {
                $(this).data("ajaxSubmitObject", new AjaxSubmit(this, options));
            }
        });
    };

})(jQuery);
